import React, { useState } from 'react';
import { generateId } from './utilities';
import WinsList from './WinsList';
import { SearchDropdown } from './searchDropdown';
import { badgeColours } from './utilities';
import { elementsOverlap } from './utilities';

export function AddWin({addWin, wins, setWins}) {

  const [text, setText] = useState('');

  const handleTextChange = ({target}) => {
    const { value } = target;
    setText(value);
  };
    
  function createWin(content) {
      
    const top = (document.getElementById("winsContainer").clientHeight) - 170;
    const newTop = Math.random() * (top - 30)
    
    //const finalTop = newTop/top * 100;
      
    //console.log("top: " + top + " " + "newTop: " + newTop + "result: " + finalTop);
    
    /*
    
    next step:
    
    if window height changes, check each 'win' and make sure it's still visible.
    
    */
    
    const left = (document.getElementById("winsContainer").clientWidth) - 170;
    const newLeft = Math.random() * (left - 100)
    
    const finalLeft = newLeft/left * 100;
    
    
    
    /* new function:
        check each win.top in wins
        if newTop is within 100px either side, minus or add 100px to it.
        but if the exiting top is after the maximum, only minus 100.
        
        same for width
    
    */
    
    
    //console.log('new top: ' + newTop);

      

    function colourNumber() {
        
        var num = 0;
        
        
        if (wins.length == 0) {
            num = 0
        } else if (wins[wins.length - 1].colour.num == 10) {
            num =  0
        } else {
            num = wins[wins.length - 1].colour.num 
        };
        
        /*
        if (wins.length != 0) {
        console.log("last colour code: " + wins[wins.length - 1].colour.code);
        }
        */
        
        //console.log("colour code: " + num + ", " + badgeColours[num].code);
        return num; 
        
    }
      
    let num = colourNumber();
    
       const win = {
        id: generateId(),
        text: content,
        colour: badgeColours[num],
        top: newTop,
        left: finalLeft
      }; 
      
      return win;
      
  }

  const handleSubmit = (event) => {
    //alert('handleSubmit ran');
    event.preventDefault();

    
    if (text.length > 0) {
       const win = createWin(text);

      addWin(win);

      setText('');
    }
 
      
  };
    
    
    const handleClick = ({target}) => {
        console.log(target.innerHTML);
        

        const win = createWin(target.innerHTML);
        
        //wins.concat(win);
        addWin(win);
        //wins.push(win);
        
        console.log(wins.length);
        
        setText('');
        
    };
    
    
function getMatches() {
    
    const searchMatches  = WinsList.filter(win => {

                    if (text) {

                       const winSmall = win.toLowerCase();

                       if (winSmall.startsWith(text) || (winSmall.includes(text))) {
                            //console.log(winSmall);
                            return winSmall;
                       }

                    }});
    
    //console.log(searchMatches.length);
                                
    return searchMatches;
    
}

    
function SearchDropdown(props) {
    
    if (text && getMatches().length > 0) {
        
    //checkMatches();
        
    return (
        
        <div className="dropdown">
            
            <div className="suggestionsTitle">
            <h3>Suggestions</h3>
            </div>
        
            <div> 
      
                {getMatches().map((win) => (<div className="dropdownRow" key={win} onClick={handleClick}>{win}</div>


                ))}


            </div>
    
        </div>
        );
    
}};

  
  return (
    <form onSubmit={handleSubmit} className="AddWin">
      <div className="searchContainer">
      <input
        className="searchBar"
        type="text"
        value={text}
        onChange={handleTextChange}
        aria-label="Add your win:"
        placeholder="Add your win:"
      />
      <input type="submit" value="Add"/>
      </div>
      
      <SearchDropdown />

    </form>
  );
}





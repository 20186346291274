import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { AddWin } from './AddWin';
import { Win } from './Win';
import { generateId } from './utilities';

function App() {
    
  const [wins, setWins] = useState([]);
     
  const addWin = (win) => {
    setWins(wins.concat(win));
    console.log('added a win');
  };
    
  const removeWin = (winIdToRemove) => {
    setWins(wins.filter((win) => win.id !== winIdToRemove));
  };
    
  return (
    <div className="App">
      <header>
        <h1>Small Wins</h1>
      </header>
      <main>
        <AddWin addWin={addWin} wins={wins} setWins={setWins} />
        <div id="winsContainer" className="wins">
          {wins.map((win) => (
            <Win key={win.id} win={win} removeWin={removeWin} />
          ))}
        </div>
      </main>
    </div>
  );
}

export default App;

import React from 'react';

const winsList = [
        "Made my bed",
        "Got dressed today",
        "Went outside",
        "Went for a walk",
        "Talked to my family",
        "Talked to friends",
        "Made Breakfast",
        "Made Lunch",
        "Made Dinner",
        "Completed some work",
        "Watched TV",
        "Listened to music",
        "Played a game",
        "Went shopping",
        "Drove a car"
  ];

export default winsList;


import React from 'react';

export function Win(props) {
  const { win, removeWin } = props;

  const handleRemoveClick = () => {
    removeWin(win.id);
  };
    
  //const topString = win.top + "%";
    
  const leftString = win.left + "%";

  return (
    <div className="Win" style={{background: win.colour.code, marginTop: win.top, marginLeft: leftString}}>
      <button
        aria-label="Remove win"
        className="remove-button"
        onClick={handleRemoveClick}
      >
        &times;
      </button>
      <div className="winText">{win.text}</div>
    </div>
  );
}

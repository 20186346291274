let nextId = 0;
export function generateId() {
  const result = nextId;
  nextId += 1;
  return result;
}

export const badgeColours = [
    {num: 1, code: "#e3aaa5"},
    {num: 2, code: "#d62f20"},
    {num: 3, code: "#eab454"},
    {num: 4, code: "#cb5f50"},
    {num: 5, code: "#f37160"},
    {num: 6, code: "#b2d9b9"},
    {num: 7, code: "#67b8c2"},
    {num: 8, code: "#a8d9ec"},
    {num: 9, code: "#902205"},
    {num: 10, code: "#006694"}
];

export function elementsOverlap(el1, el2) {
  const domRect1 = el1.getBoundingClientRect();
  const domRect2 = el2.getBoundingClientRect();

  return !(
    domRect1.top > domRect2.bottom ||
    domRect1.right < domRect2.left ||
    domRect1.bottom < domRect2.top ||
    domRect1.left > domRect2.right
  );
}